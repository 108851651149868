import React from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useStyles } from './dialog-adicionar-produto-com-subitem-styles';
import { useCallback, useEffect, useState } from 'react';
import SemImagem from 'assets/img/sem-imagem.jpg';
import { toDecimalString } from 'utils/to-decimal';
import { AvancarIcon } from 'componentes/icons/avancar-icon';
import { VoltarIcon } from 'utils';
import { EnumTipoProduto, EnumTpProduto, MovSimplesProdutoModel } from 'api/cardapio/models/produto/mov-simples-produto-model';
import { useNavigateSubitens } from './hooks/navigate-subitens';
import { DialogSaurus } from './dialog-saurus/dialog-saurus';
import { useThemeQueries } from './util-styles';
import { CardSubitem } from './components/card-produto-subitem/card-subitem';
import { AddESub } from './components/card-produto-subitem/components/add-e-sub/button-add-e-sub';
import { ConfirmarIcon } from 'componentes/icons/confirmar-icon';
import { Carregando } from 'componentes';
import {
  CardapioStorageKeys,
  useCardapioStorage,
} from 'storage/cardapio-storage';
import { CardapioDadosProps } from 'modulos/cardapio/containers/cardapio/cardapio-header/cardapio-header-props';
import { TentarNovamenteIcon } from 'componentes/icons/tentar-novamente-icon';
import { ErroColorIcon } from 'componentes/icons/erro-color-icon';
import { MovSimplesModificadoresProdutoModel } from 'api/cardapio/models/produto/mov-simples-modificadores-produto-model';
import { CardSubitemModificador } from './components/card-produto-subitem-modificador/card-subitem-modificador';
export interface DialogAdicionarProdutoComSubItensProps {
  aberto: boolean;
  produto: MovSimplesProdutoModel;
  edit?: boolean;
  fechar: () => void;
  editarProdutoComSubItem: (
    produto: MovSimplesProdutoModel,
    adicionais: MovSimplesProdutoModel[],
    valorFinal: number
  ) => Promise<any>;
  adicionarProdutoComSubItem: (
    produto: MovSimplesProdutoModel,
    adicionais: MovSimplesProdutoModel[],
    valorFinal: number
  ) => Promise<any>;
}

export const DialogAdicionarProdutoComSubItem = ({
  aberto,
  produto,
  edit,
  adicionarProdutoComSubItem,
  editarProdutoComSubItem,
  fechar,
}: DialogAdicionarProdutoComSubItensProps) => {
  // Hooks
  const {
    currentProduct,
    productFinal,
    next,
    goBack,
    productsAdicionais,
    handleAlterQuantity,
    isGoback,
    productInitial,
    carregando,
    erro,
    getAllSubItens,
  } = useNavigateSubitens(produto, edit);
  const { getRegistro } = useCardapioStorage();
  const dadosGerais = getRegistro(
    CardapioStorageKeys.DadosGerais,
    false
  ) as CardapioDadosProps;
  const { empresa } = dadosGerais;
  const theme = useTheme();

  // STATES E REFS
  const [qtd, setQtd] = useState<number>(productInitial.qCom);
  const [validButton, setValidButton] = useState<boolean>(
    (currentProduct?.modificadores.filter((m) => m.qAtual < m.qMin) ?? [])
      .length > 0
      ? true
      : false
  );

  const handleSubItem = useCallback(
    async (
      produto: MovSimplesProdutoModel,
      adicionais: MovSimplesProdutoModel[],
      valorFinal: number
    ) => {
      if (!edit)
        await adicionarProdutoComSubItem(produto, adicionais, valorFinal);
      else await editarProdutoComSubItem(produto, adicionais, valorFinal);

      fechar();
    },
    [adicionarProdutoComSubItem, edit, editarProdutoComSubItem, fechar]
  );

  // AUX
  const classes = useStyles();
  const { isMobile } = useThemeQueries();

  useEffect(() => {
    const validarModificadores =
      currentProduct?.modificadores.filter((m) => m.qAtual < m.qMin) ?? [];
    setValidButton(validarModificadores.length > 0 ? true : false);
  }, [currentProduct]);

  const img = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImg}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, produto.xProd]
  );

  const imgInfo = useCallback(
    (imagemUrl?: string) => {
      try {
        if (imagemUrl && imagemUrl?.length > 0) {
          return (
            <img
              src={imagemUrl}
              className={classes.cardImgInfo}
              alt={produto.xProd}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = SemImagem;
              }}
            />
          );
        }

        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = SemImagem;
            }}
          />
        );
      } catch (err) {
        return (
          <img
            src={SemImagem}
            className={classes.cardImg}
            alt={produto.xProd}
          />
        );
      }
    },
    [classes.cardImg, classes.cardImgInfo, produto.xProd]
  );

  return (
    <DialogSaurus
      aberto={aberto || false}
      tamanho='xs'
      titulo={'Personalize seu Produto'}
      isButtonTitleClose
      handleClickClose={fechar}
      fullScreen={isMobile}
      bottomArea={(
        <Box px={3}>
          {(!erro || erro.length === 0) && (
            <Grid
              container
              spacing={2}
              style={{ marginTop: '8px', marginBottom: '8px' }}
            >
              {isGoback && (
                <Grid item xs={6}>
                  <Button
                    variant='outlined'
                    onClick={goBack}
                    fullWidth
                    className='round'
                  >
                    <VoltarIcon tipo='BUTTON' />
                    Voltar
                  </Button>
                </Grid>
              )}
              {!carregando && (
                <Grid item xs={isGoback ? 6 : 12}>
                  <Button
                    variant='contained'
                    color='primary'
                    fullWidth
                    className='round'
                    disabled={validButton}
                    onClick={() => {
                      if (currentProduct === null) {
                        handleSubItem(
                          productFinal.current,
                          productsAdicionais,
                          productFinal.current.vUnCom +
                          productsAdicionais.reduce(
                            (acc, current) => acc + current.vFinal,
                            0
                          )
                        );
                      } else {
                        next();
                      }
                    }}
                  >
                    {currentProduct === null ? (
                      <ConfirmarIcon tipo='BUTTON_PRIMARY' />
                    ) : (
                      <AvancarIcon tipo='BUTTON_PRIMARY' />
                    )}
                    {currentProduct === null ? 'Confirmar' : 'Próximo'}
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      )}
    >
      <Grid className={classes.cardContainer}>
        <div style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
          {isGoback && !carregando && currentProduct !== null && (
            <div style={{ display: 'flex', marginBottom: 12 }}>
              {imgInfo(productFinal.current.imgUrl)}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flex: 1,
                }}
              >
                <Grid>
                  <Typography
                    variant='body1'
                    style={{
                      fontWeight: 400,
                      fontSize: '10px',
                      marginLeft: 8,
                    }}
                  >
                    Nome
                  </Typography>
                  <Typography
                    variant='body1'
                    style={{
                      fontWeight: 600,
                      fontSize: '18px',
                      marginLeft: 8,
                      lineHeight: '18px',
                      marginBottom: '8px',
                    }}
                  >
                    {productFinal.current.xProd}
                  </Typography>
                </Grid>

                {(((productInitial.tpProduto as unknown) as EnumTipoProduto) ===
                  EnumTipoProduto.Produto
                  ? EnumTpProduto.ProdutoComSubItem
                  : EnumTpProduto.Combo) !== EnumTpProduto.Combo && (
                    <Grid>
                      <Typography
                        variant='body1'
                        style={{
                          fontWeight: 400,
                          fontSize: '10px',
                          marginLeft: 8,
                        }}
                      >
                        Total
                      </Typography>
                      <Typography
                        variant='body1'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          marginLeft: 8,
                          lineHeight: '18px',
                        }}
                      >
                        {toDecimalString(
                          productFinal.current.vUnCom +
                          productsAdicionais.reduce(
                            (acc, current) => acc + current.vFinal,
                            0
                          ),
                          2
                        )}
                      </Typography>
                    </Grid>
                  )}
              </div>
            </div>
          )}

          {erro.length === 0 && (
            <div style={{ display: 'flex', marginBottom: 8 }}>
              {img(currentProduct?.imgUrl ?? productFinal.current.imgUrl)}
              <div style={{ flex: 1 }}>
                <Typography
                  variant='body1'
                  style={{ fontWeight: 400, fontSize: '10px', marginLeft: 8 }}
                >
                  Nome
                </Typography>
                <Typography
                  variant='body1'
                  style={{
                    fontWeight: 600,
                    fontSize: '18px',
                    marginLeft: 8,
                    lineHeight: '18px',
                    marginBottom: '8px',
                  }}
                >
                  {currentProduct?.xProd ?? productFinal.current.xProd}
                </Typography>

                {(((productInitial.tpProduto as unknown) as EnumTipoProduto) ===
                  EnumTipoProduto.Produto
                  ? EnumTpProduto.ProdutoComSubItem
                  : EnumTpProduto.Combo) !== EnumTpProduto.Combo && (
                    <>
                      <Typography
                        variant='body1'
                        style={{
                          fontWeight: 400,
                          fontSize: currentProduct === null ? '12px' : '10px',
                          marginLeft: 8,
                        }}
                      >
                        {currentProduct === null ? 'Total' : 'Categoria'}
                      </Typography>

                      <Typography
                        variant='body1'
                        style={{
                          fontWeight: 600,
                          fontSize: '14px',
                          marginLeft: 8,
                          lineHeight: '18px',
                        }}
                      >
                        {currentProduct !== null
                          ? currentProduct?.categoria
                          : toDecimalString(
                            (productInitial.vUnCom +
                              productsAdicionais.reduce(
                                (acc, current) => acc + current.vFinal,
                                0
                              )) *
                            qtd,
                            2
                          )}
                      </Typography>
                    </>
                  )}

                {currentProduct?.infAdic && (
                  <Grid
                    style={{
                      padding: 8,
                    }}
                  >
                    <Typography
                      variant='body1'
                      style={{
                        fontWeight: 400,
                        fontSize: currentProduct === null ? '12px' : '10px',
                      }}
                    >
                      Descrição
                    </Typography>
                    <Typography
                      variant='body1'
                      style={{
                        fontSize: '14px',
                        lineHeight: '18px',
                      }}
                    >
                      {currentProduct.infAdic}
                    </Typography>
                  </Grid>
                )}
              </div>

              {!carregando && currentProduct === null && (
                <AddESub
                  model={productFinal.current}
                  qtd={qtd}
                  setQtd={setQtd}
                  alterarQtdeProduto={(
                    valorProdutoPai: number,
                    produto: MovSimplesProdutoModel,
                    qtd: number,
                    modificador?: MovSimplesModificadoresProdutoModel,
                    prodGradeId?: string,
                    fator?: 'add' | 'sub'
                  ) => {
                    productFinal.current = {
                      ...productFinal.current,
                      qCom: qtd,
                    };
                  }}
                  valorProdutoPai={productFinal.current.vProd}
                  noZeroMin
                />
              )}
            </div>
          )}

          {currentProduct === null &&
            productInitial.prodSubItem
              .filter((p) => (p.infoSubItem?.qPadrao ?? 0) > 0)
              .filter((p) => p.qCom > 0).length > 0 && (
              <>
                <Typography variant='h6'>Inclusos no Produto</Typography>
                <Divider className={classes.divider} />
              </>
            )}

          <div>
            {currentProduct?.prodSubItem &&
              currentProduct?.prodSubItem?.length > 0 &&
              currentProduct?.prodSubItem
                .filter((prod) => !prod.modificadorId)
                .map((p) => (
                  <CardSubitem
                    key={p.id}
                    produto={p}
                    handleClickAddOrSub={handleAlterQuantity}
                    edit={edit}
                    qtdPai={productInitial.qCom}
                    valorProdutoPai={currentProduct.vProd}
                  />
                ))}
          </div>

          {currentProduct?.modificadores &&
            currentProduct?.modificadores?.length > 0 &&
            currentProduct?.modificadores.map((modificador) => (
              <Grid style={{ marginRight: 4, marginTop: 16 }}>
                <Grid style={{ display: 'flex', alignItems: 'center' }}>
                  <Grid style={{ alignItems: 'center', flex: 1 }}>
                    <Typography variant='h6'>{modificador.nome}</Typography>
                    <Typography variant='caption'>
                      {modificador.descricao}
                    </Typography>
                  </Grid>
                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    {!(modificador.qMin === 1 && modificador.qMax === 1) &&
                      modificador.qMin !== 0 && (
                        <Grid
                          className={classes.badgeInfoModificador}
                          style={{ marginBottom: 4 }}
                        >
                          <Typography
                            variant='caption'
                            style={{ color: theme.palette.common.white }}
                          >
                            Minímo de {modificador.qMin}
                          </Typography>
                        </Grid>
                      )}
                    <Grid className={classes.badgeInfoModificador}>
                      <Typography
                        variant='caption'
                        style={{ color: theme.palette.common.white }}
                      >
                        {modificador.qMin === 1 && modificador.qMax === 1
                          ? 'Obrigatório'
                          : `${modificador.qAtual} de ${modificador.qMax}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Divider className={classes.divider} />

                {currentProduct?.prodSubItem
                  .filter((p) => p.modificadorId === modificador.id &&
                    p.modificadorUnicoId === modificador.unicoId)
                  .sort((a, b) => a.ordem - b.ordem)
                  .map((p) => (
                    <CardSubitemModificador
                      key={p.id}
                      produto={p}
                      handleClickAddOrSub={handleAlterQuantity}
                      edit={edit}
                      qtdPai={productInitial.qCom}
                      modificador={modificador}
                      qtdModificador={currentProduct?.prodSubItem
                        .filter(
                          (p) =>
                            p.modificadorId === modificador.id &&
                            p.modificadorUnicoId === modificador.unicoId &&
                            p.qCom > 0
                        )
                        .reduce((acc, current) => acc + current.qCom, 0)}
                      valorProdutoPai={currentProduct.vProd}
                      tpProdutoPai={currentProduct.tpProduto}
                    />
                  ))}
              </Grid>
            ))}

          {currentProduct === null && (
            <div>
              {productInitial.prodSubItem &&
                productInitial.prodSubItem?.length > 0 &&
                productInitial.prodSubItem
                  .filter((p) => (p.infoSubItem?.qPadrao ?? 0) > 0)
                  .filter((p) => p.qCom > 0)
                  .map((p) => {
                    return (
                      <CardSubitem
                        key={p.id}
                        produto={{
                          ...p,
                          qCom:
                            p.qCom > p.infoSubItem!.qPadrao
                              ? p.infoSubItem!.qPadrao
                              : p.qCom,
                        }}
                        handleClickAddOrSub={handleAlterQuantity}
                        verificar
                        edit={edit}
                        qtdPai={productInitial.qCom}
                        valorProdutoPai={productInitial.vProd}
                      />
                    );
                  })}
            </div>
          )}
          {currentProduct === null && productsAdicionais.length > 0 && (
            <>
              <Typography variant='h6'>Adicionais</Typography>
              <Divider className={classes.divider} />
            </>
          )}
          {currentProduct === null && (
            <div>
              {productsAdicionais.length > 0 &&
                productsAdicionais.map((p) => (
                  <CardSubitem
                    key={p.id}
                    produto={p}
                    handleClickAddOrSub={handleAlterQuantity}
                    showValue
                    verificar
                    edit={edit}
                    qtdPai={1}
                    qtdModificador={productsAdicionais
                      .filter(
                        (prod) =>
                          prod.modificadorId === p.modificadorId &&
                          prod.qCom > 0
                      )
                      .reduce((acc, current) => acc + current.qCom, 0)}
                    valorProdutoPai={productFinal.current.vProd}
                  />
                ))}
            </div>
          )}
        </div>

        {carregando && (
          <>
            <Carregando
              carregando
              height={56}
              titulo='Aguarde...'
              className={classes.skeleton}
              tipo='skeleton'
            />
            <Carregando
              carregando
              height={56}
              titulo='Aguarde...'
              className={classes.skeleton}
              tipo='skeleton'
            />
            <Carregando
              carregando
              height={56}
              titulo='Aguarde...'
              className={classes.skeleton}
              tipo='skeleton'
            />
          </>
        )}
        {erro.length > 0 && (
          <>
            <Box textAlign='center' mb={2}>
              <Box className={classes.erroIcon}>
                <ErroColorIcon tipo='BUTTON' />
              </Box>
              <Typography>
                Ops, não conseguimos buscar os produtos, tente novamente e caso
                ainda não busque, entre em contato conosco pelo telefone{' '}
                {empresa.fone}. Detalhe: {erro}
              </Typography>
            </Box>
            <Button onClick={getAllSubItens}>
              <TentarNovamenteIcon tipo='BUTTON_PRIMARY' />
              Tentar novamente
            </Button>
          </>
        )}
      </Grid>
    </DialogSaurus>
  );
};

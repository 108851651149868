import { Grid, Typography } from "@material-ui/core"
import React, { useCallback } from "react"
import { useStyles } from "./sacola-totais-styles"
import { SacolaTotaisProps } from "./sacola-totais-props"
import { EnumTpProduto, MovSimplesProdutoModel } from "api/cardapio/models/produto/mov-simples-produto-model"
import { ItemSacola } from "storage"

export const SacolaTotais = ({ itens }: SacolaTotaisProps) => {
    const classes = useStyles()

    const total = useCallback(() => {
        let total =
            (itens || ([] as MovSimplesProdutoModel[])).length > 0
                ? itens.filter(x => x.indFin).map((item) => {

                    if (item.tpProduto === EnumTpProduto.Combo) {
                        return 0;
                    }
                    if (item.vUnCom === 0) {
                        return 0;
                    }
                    return item.vUnCom * (item.qComModificador > 0 ? item.qComModificador : item.qCom) + item.vAcrescUsuario - item.vDescUsuario
                }).reduce((prev, next) => prev + next, 0)
                : 0;

        return (total || 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }, [itens]);

    const totalItens = useCallback(() => {
        let total =
            (itens || ([] as ItemSacola[])).length > 0
                ? itens.filter(x => !x.produtoPaiId).map((item) => item.qCom).reduce((prev, next) => prev! + next!, 0)
                : 0;

        return total || 0;
    }, [itens]);

    return (
        <Grid container style={{ marginTop: '1rem' }}>
            <Grid item xs>
                <Typography variant="body2" color="textSecondary">
                    Itens
                </Typography>
                <Typography variant="h5" color="textPrimary" className={classes.textoTotais}>
                    {totalItens()}
                </Typography>
            </Grid>
            <Grid item xs={'auto'}>
                <Typography variant="body2" color="textSecondary">
                    Total
                </Typography>
                <Typography variant="h5" color="textPrimary" className={classes.textoTotais}>
                    {total()}
                </Typography>
            </Grid>
        </Grid>
    )
}